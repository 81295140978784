import React from 'react';
import Card from './Card';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const Post = ({ post }) => (
  <Card className="mb-8">
    <p className="text-xl font-semibold"><Link to={`/blog/${post.slug}`}>{post.title}</Link></p>
    <div
      dangerouslySetInnerHTML={{
        __html: post.body.childMarkdownRemark.html,
      }}
    />
  </Card>
);

export default Post;
