import React from 'react';
import Card from './Card';
import Img from 'gatsby-image';
import ImageCard from '../components/ImageCard';
import Button from '../components/Button';
import Video from '../components/Video'

const Event = ({ event }) => {
  const eventStartDate = new Date(Date.parse(event.startDateFormatted));
  const todayDate = new Date();
  const isNew = eventStartDate.getTime() >= todayDate.getTime();

  return (
    // <Card className="mb-2">

    <section id="events" className="py-5 pb-10">
      <div className="container mx-auto items-center flex flex-col lg:flex-row">
        <div className="lg:w-2/3 px-3">
          <div className="lg:pr-32 xl:pr-48">
            <h2 className="text-2xl lg:text-3xl xl:text-4xl font-semibold leading-tight">{event.name}</h2>
            <p className="mt-8 text-xl font-light leading-relaxed">
              {event.startDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: event.excerpt.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
        <div
          className={`mt-10 lg:mt-0 w-full lg:w-1/3`}
        >
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row md:-mx-3">

              <div className="flex-1 px-3">

                {
                  event.youtube ?
                    <Video
                      videoSrcURL={event.youtube}
                      videoTitle={event.name}
                    />
                    :
                    <ImageCard className="items-center text-center">
                      <Img
                        className="w-full h-auto z--1 max-w-none"
                        alt={event.name}
                        fluid={event.eventImage.fluid}
                      />
                    </ImageCard>
                }
                
                <p className="mt-8 md:mt-12">
                  {
                    isNew ?
                      <a href={event.url} target="_blank"><Button size="lg">Register for Upcoming Event</Button></a>
                      :
                      <a href={event.url} target="_blank"><Button size="lg">View Past Event</Button></a>
                  }
                </p>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section >
    // </Card>
  )
};

export default Event;
