import React from 'react';
import ImageCard from './ImageCard';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"

const TeamCard = ({ teamMember }) => (
  <ImageCard className="items-center text-center">
    <Img
      className="w-full z--1 max-w-none"
      alt={teamMember.name}
      fluid={teamMember.profileImage.fluid}
    />
    <p className="text-xl font-semibold">{teamMember.name}</p>
    <div className="px-6 py-4">
      <div className="text-l mb-2">{teamMember.title}</div>
    </div>
    <div>
      <ul className="list-none items-center">
        <li className={!teamMember.linkedIn ? `invisible` : `inline-block p-1`}>
          <a href={teamMember.linkedIn}>
            <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
          </a>
        </li>
        <li className={!teamMember.email ? `invisible` : `inline-block p-1`}>
          <a href={teamMember.email}>
            <FontAwesomeIcon icon={faEnvelope} size="1x" />
          </a>
        </li>
      </ul>
    </div>
  </ImageCard>
);

export default TeamCard;
