import React from 'react';
import Button from '../components/Button';

const Career = ({ career }) => {

  return (
    <section id="careers" className="py-5 pb-10">
      <div className="container mx-auto items-center flex flex-col lg:flex-row">
        <div className="px-3">
          <h2 className="text-2xl lg:text-3xl xl:text-4xl font-semibold leading-tight">{career.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: career.excerpt.childMarkdownRemark.html,
            }}
          />
          <br />
          <a href={`/careers/${career.slug}`}><Button size="lg">View Job Details</Button></a>
        </div>
      </div>
    </section >
  )
};

export default Career;
