import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout/Layout'

class EventTemplate extends React.Component {
  render() {
    const event = get(this.props, 'data.contentfulEvent')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <section id="testimonials" className="py-20 lg:py-10">
          <div className="container mx-auto">
            <Helmet title={`${event.name} | ${siteTitle}`} />
            <div>
              <Img

                alt={event.name}
                fluid={event.eventImage.fluid}
              />
            </div>
            <div className="wrapper">
              <h1 className="section-headline">{event.name}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: event.excerpt.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        }
    }
    contentfulEvent(slug: { eq: $slug }) {
      name
      url
      slug
      youtube
      startDate(formatString: "MMMM Do, YYYY")
      endDate(formatString: "MMMM Do, YYYY")
      eventImage {
          fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      details {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
