import React from 'react';
import ImageCard from './ImageCard';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const PartnerCard = ({ partner }) => (
  <div>
    <a href={partner.url} target="_blank" title={partner.name}>
      <Img
      className="w-full z--1 max-w-none"
      alt={partner.name}
      fluid={partner.logo.fluid}
    /></a>
  </div>
);

export default PartnerCard;
