import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout/Layout'
import ImageCard from '../components/ImageCard';

class AmbassadorTemplate extends React.Component {
  render() {
    const ambassador = get(this.props, 'data.contentfulAmbassador')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Helmet title={`Ambassador ${ambassador.name} | ${siteTitle}`} />
        <section id="testimonials" className="py-20">
          <div className="container mx-auto items-center flex flex-col lg:flex-row">
            <div className="lg:w-2/3 px-3">

              <div className="lg:pr-32 xl:pr-48">
                <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">{ambassador.name}</h1>
                <div className="mt-8"
                  dangerouslySetInnerHTML={{
                    __html: ambassador.bio.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
            <div
              className={`mt-10 lg:mt-0 w-full lg:w-1/3`}
            >
              <div className="container mx-auto">
                <div className="flex flex-col md:flex-row md:-mx-3">
                  <div className="flex-1 px-3">
                    <ImageCard className="items-center text-center">
                      <Img
                        className="w-full h-auto z--1 max-w-none"
                        alt={ambassador.name}
                        fluid={ambassador.profileImage.fluid}
                      />
                    </ImageCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AmbassadorTemplate

export const pageQuery = graphql`
  query AmbassadorBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        }
    }
    contentfulAmbassador(slug: { eq: $slug }) {
      name
      slug
      startDate(formatString: "MMMM Do, YYYY")
      profileImage {
          fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      bio {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
