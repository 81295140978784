import './src/css/tailwind.css';
import './src/css/main.css';

// gatsby-browser.js
// ES6
export const onClientEntry = () => {
    window.onload = () => {
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line global-require
            require('smooth-scroll')('a[href*="#"]', {
                speed: 800,
                speedAsDuration: true,
                easing: 'easeInOutCubic'
            })
        }
    }
}