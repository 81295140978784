import React from 'react';
import get from 'lodash/get'

import AmbassadorCard from '../components/AmbassadorCard';
import Layout from '../components/layout/Layout';

class Ambassors extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const ambassadors = get(this, 'props.data.allContentfulAmbassador.edges')

    return (
      <Layout>
        <section id="testimonials" className="py-20 lg:py-10">

          <div className="container mx-auto">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold mb-5 text-center">Ambassadors</h1>
            <p className="text-xl mb-5 font-light text-center">We need passionate individuals to make a real difference by increasing awareness of our vital work. As a Community Ambassador, you will help ABAWT to reach a wider audience and inspire local community organisations, groups and individuals in the techhub or city you’re rooted in to develop a support network of people who use technology in and with Africa. </p>
            <p className="text-xl mb-5 font-light text-center">The Community Ambassador is an interesting, flexible and varied role which will enable you to develop positive and rewarding networks with local and international community members. (NB: This is a voluntary role.)</p>
            <p className="text-xl mb-5 font-light text-center">
              <a href="mailto:team@abawt.io?subject=RE: Ambassador Request">Want to become an ambassador?</a>
            </p>
            <div className="text-center mb-10">
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
            </div>
            {/* <h3 className="text-3xl font-semibold text-center text-gray-800">Some of our mentors...</h3> */}
            <div className="flex flex-col md:flex-row md:-mx-3">
              {ambassadors.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <AmbassadorCard ambassador={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Ambassors

export const pageQuery = graphql`
  query AmbassorsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAmbassador(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          slug
          startDate(formatString: "MMMM Do, YYYY")
          profileImage {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
