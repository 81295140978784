import React from 'react';
import Card from './Card';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const AmbassadorCard = ({ ambassador }) => (
  <Card className="mb-8">
    <Img
      className="w-full z--1"
      alt={ambassador.name}
      fluid={ambassador.profileImage.fluid}
    />
    <p className="text-xl font-semibold"><Link to={`/ambassadors/${ambassador.slug}`}>{ambassador.name}</Link></p>
    <div
      dangerouslySetInnerHTML={{
        __html: ambassador.shortBio.childMarkdownRemark.html,
      }}
    />
    <div className="flex items-center mt-8">

      <div>
        <Link to={`/ambassadors/${ambassador.slug}`}>{ambassador.title}</Link>
      </div>
    </div>
  </Card>
);

export default AmbassadorCard;
