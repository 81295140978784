import React from 'react';
import Helmet from 'react-helmet'

import Footer from './Footer';
import Header from './Header';
import favicon from '../../img/logo.png'


const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
