import React, { useState, useEffect } from 'react';
import logo from '../../img/logo.png'
import { Link } from 'gatsby';
import './header.css'

const Header = () => {
  const [open, setOpen] = useState(true);

  const navToggle = () => {
    if (typeof window !== "undefined") {
      setOpen(!open);

      const btn = document.getElementById('menuBtn');
      const nav = document.getElementById('menu');

      btn.classList.toggle('open');
      nav.classList.toggle('flex');
      nav.classList.toggle('hidden');
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener('scroll', () => {
        const nav = document.getElementById('site-menu');
        const header = document.getElementById('top');
        if (window.scrollY >= 400) { // adjust this value based on site structure and header image height
          nav.classList.add('nav-sticky');
          header.classList.add('pt-scroll');
        } else {
          nav.classList.remove('nav-sticky');
          header.classList.remove('pt-scroll');
        }
      })
    }
  })

  return (
    // <header className="sticky top-0 bg-white shadow z-10">
    //   <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
    //     <div className="flex items-center text-2xl">
    //       <div className="w-12 mr-3">
    //         {/* <LogoIcon /> */}
    //         <Link to="/"><img src={logo} /></Link>
    //       </div>
    //       {/* Lander */}
    //     </div>
    //     <div className="flex mt-4 sm:mt-0">
    //       <Link className="px-4" to="/">Home</Link>
    //       <Link className="px-4" to="/#about">About Us</Link>
    //       <Link className="px-4" to="/#team">Team</Link>
    //       {/* <Link className="px-4" to="/#partners">Partners</Link> */}
    //       {/* <Link className="px-4" to="/#ambassadors">Ambassadors</Link> */}
    //       <Link className="px-4" to="/mentors">Mentors</Link>
    //       {/* <Link className="px-4" to="/blog">Blog</Link> */}
    //       <Link className="px-4" to="/events">Events</Link>
    //       <Link className="px-4" to="https://medium.com/abawt">Blog</Link>
    //       {/* <AnchorLink className="px-4" href="/#features">
    //         Features
    //       </AnchorLink>
    //       <AnchorLink className="px-4" href="/#services">
    //         Services
    //       </AnchorLink>
    //       <AnchorLink className="px-4" href="/#stats">
    //         Stats
    //       </AnchorLink>
    //       <AnchorLink className="px-4" href="/#testimonials">
    //         Testimonials
    //       </AnchorLink> */}

    //     </div>
    //     <div className="hidden md:block">
    //       <Button className="text-sm">Get in Touch</Button>
    //     </div>
    //   </div>

    // </header>


    <header id="top" className="w-full flex flex-col fixed sm:relative bg-white pin-t pin-r pin-l">
      <nav id="site-menu" className="z-10 flex flex-col sm:flex-row w-full justify-between items-center px-4 sm:px-6 py-1 bg-white shadow sm:shadow-none border-t-4">
        <div className="w-full sm:w-auto self-start sm:self-center flex flex-row sm:flex-none flex-no-wrap justify-between items-center">
          <Link to="/" className="no-underline w-12 mr-3"><img src={logo} /></Link>
          <button id="menuBtn" className="hamburger block sm:hidden focus:outline-none" type="button" onClick={navToggle}>
            <span className="hamburger__top-bun"></span><span className="hamburger__bottom-bun"></span>
          </button>
        </div>
        <div id="menu" className="w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 sm:py-0 sm:pb-0 hidden">
          <Link className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" to="/">Home</Link>
          <Link className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" to="/#about">About Us</Link>
          <Link className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" to="/#team">Team</Link>
          {/* <Link className="px-4" to="/#ambassadors">Ambassadors</Link> */}
          <Link className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" to="/mentors">Mentors</Link>
          {/* <Link className="px-4" to="/blog">Blog</Link> */}
          <Link className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" to="/events">Events</Link>
          <a className="px-4 w-full no-underline sm:w-auto sm:pr-4 py-2 sm:py-1 sm:pt-2" href="https://medium.com/abawt" target="_blank">Blog</a>
        </div>
      </nav>
    </header>
  )
};

export default Header;
