import React from 'react';
import get from 'lodash/get';
import { Helmet } from 'react-helmet'

import Button from '../components/Button';
import Card from '../components/Card';
import TeamCard from '../components/TeamCard';
import PartnerCard from '../components/PartnerCard';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HeroImage from '../svg/HeroImage.svg';

class Home extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const teamMembers = get(this, 'props.data.allContentfulTeamMember.edges')
    const partners = get(this, 'props.data.allContentfulPartner.edges')
    const ambassadors = get(this, 'props.data.allContentfulAmbassador.edges')

    return (
      <Layout>
        <Helmet title={`${siteTitle}`} />
        <section className="pt-20 md:pt-40">
          <div className="container mx-auto px-8 lg:flex">
            <div className="text-center lg:text-left lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                A Better Africa With Technology
              </h1>
              <p className="text-xl lg:text-2xl mt-6 font-light">
                We are here to build and activate a global ecosystem of like-minded individuals bound by the aspiration to contribute to, share in, and learn from African tech ecosystems
              </p>
              <p className="mt-8 md:mt-12">
                <a href="/#about"><Button size="lg">Learn More</Button></a>
              </p>
              {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
            </div>
            <div className="lg:w-1/2">
              <img src={HeroImage} alt="React Logo" />
            </div>
          </div>
        </section>
        <section id="about" className="py-20 lg:pb-40 lg:pt-48">
          <div className="container mx-auto text-center">
            <div className="text-center max-w-xl mx-auto">
              <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-5">About ABAWT</h2>
              <h3 className="text-xl mb-5 font-light">Founded in Feb 2016 and based in Berlin, A-Better-Africa-With-Tech helps accelerate entrepreneurship across Africa.</h3>
              <div className="text-center mb-10">
                <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              </div>
            </div>
            <h3 className="text-2xl lg:text-3xl xl:text-4xl font-semibold text-gray-800">How?</h3>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <h4 className="font-semibold text-xl">Community</h4>
                  <p className="mt-4">
                    Through our regular meetups and highly communicative groups we create ideal networking environments so people can discuss startup ideas or brainstorm solutions to problems.
                  </p>
                  <a href="/events">Find out more</a>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <h4 className="font-semibold text-xl">Mentorship</h4>
                  <p className="mt-4">
                    Our various partnerships with startup programs allow us to provide guided mentorship opportunities to budding entrepreneus in Africa and help accelerate ideas with potential and lastingchange.
                  </p>
                  <a href="/mentors">Find out more</a>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8">
                  <h4 className="font-semibold text-xl">Tech Support</h4>
                  <p className="mt-4">
                    With our network consisting of all types of skills and background (From UI/UX and Business to IT Infrastructure) we help with startup mentorship and get you in touch with the right people for advice.
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </section>

        <section id="team" className="py-20">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-5">Team</h2>
            <div className="flex flex-col md:flex-row md:-mx-3">
              {teamMembers.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <TeamCard teamMember={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>

        {/* <SplitSection
          id="services"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Our team of enthusiastic marketers will analyse and evaluate how your company stacks
                against the closest competitors
          </p>
            </div>
          }
          secondarySlot={<SvgCharts />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Design And Plan Your Business Growth Steps
          </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Once the market analysis process is completed our staff will search for opportunities
                that are in reach
          </p>
            </div>
          }
          secondarySlot={<SvgCharts />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Search For Performance Optimization
          </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                With all the information in place you will be presented with an action plan that your
                company needs to follow
          </p>
            </div>
          }
          secondarySlot={<SvgCharts />}
        />
        */}

        <SplitSection
          id="partners"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Partners</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                We're proud to partner with leading change makers, organizations and ecosystem players globally, who share our mission of supporting Africa's future tech leaders.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed"><a href="mailto:team@abawt.io?subject=RE: Partner Request">Want to partner with us?</a></p>
            </div>
          }
          secondarySlot={
            <div className="container mx-auto">
              <div className="flex flex-col md:flex-row md:-mx-3">
                {partners.map(({ node }) => {
                  return (
                    <div key={node.slug} className="flex-1 px-3">
                      <PartnerCard partner={node} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
        />

        <SplitSection
          id="ambassadors"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Ambassadors</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Our ambassadors represent ABAWT in techhubs throughout Africa and Europe, growing our community and connecting people through our events and programmes.
              </p>
              <p className="mt-8 text-xl font-light leading-relaxed"><a href="/ambassadors">Learn more about ambassadorship</a></p>
            </div>
          }
          secondarySlot={
            <div className="container mx-auto">
              <div className="flex flex-col md:flex-row md:-mx-3">
                {ambassadors.map(({ node }) => {
                  return (
                    <div key={node.slug} className="flex-1 px-3">
                      <TeamCard teamMember={node} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
        />


        {/* <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
          <h3 className="text-5xl font-semibold">Get updates on a regular basis?</h3>
          <p className="mt-8 text-xl font-light">
            <input type="email" class="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder="jane@y.com" />
          </p>
          <p className="mt-8">
            <Button size="xl">Get Started Now</Button>
          </p>
        </section> */}
      </Layout>
    )
  }
}


export default Home

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTeamMember(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          slug
          title
          linkedIn
          email
          startDate(formatString: "MMMM Do, YYYY")
          profileImage {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulAmbassador(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          slug
          startDate(formatString: "MMMM Do, YYYY")
          profileImage {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          logo {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          url
          slug
          title
          startDate(formatString: "MMMM Do, YYYY")
          profileImage {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
