import React from 'react';
import get from 'lodash/get'

import Career from '../components/Career';
import Layout from '../components/layout/Layout';

class Careers extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const careers = get(this, 'props.data.allContentfulCareer.edges')

    return (
      <Layout>
        <section id="testimonials" className="py-20 lg:py-10">
          <div className="container mx-auto">
            <h1 className="text-6xl md:text-7xl font-bold mb-5 text-center">Careers</h1>
            <p className="text-xl mb-5 font-light text-center">Join us in shaping the future of an impactful international community shaping Africa’s future.</p>
            <div className="text-center mb-10">
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
            </div>
            {/* <h3 className="text-3xl font-semibold text-center text-gray-800">Past and upcoming events...</h3> */}
            <div className="flex flex-col">
              {careers.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <Career career={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Careers

export const pageQuery = graphql`
  query CareersQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCareer(sort: { fields: [title], order: DESC }) {
      edges {
        node {
          title
          slug
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
