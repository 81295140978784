import React from 'react';
import get from 'lodash/get'

import Event from '../components/Event';
import Layout from '../components/layout/Layout';

class Events extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const events = get(this, 'props.data.allContentfulEvent.edges')

    return (
      <Layout>
        <section id="testimonials" className="py-20 lg:py-10">
          <div className="container mx-auto">
            <h1 className="text-6xl md:text-7xl font-bold mb-5 text-center">Events</h1>
            <p className="text-xl mb-5 font-light text-center">Join our events to if you'd like to connect with others contributing towards Africa</p>
            <div className="text-center mb-10">
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
            </div>
            {/* <h3 className="text-3xl font-semibold text-center text-gray-800">Past and upcoming events...</h3> */}
            <div className="flex flex-col">
              {events.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <Event event={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Events

export const pageQuery = graphql`
  query EventsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEvent(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          url
          slug
          youtube
          startDate(formatString: "MMMM Do, YYYY")
          startDateFormatted: startDate(formatString: "YYYY-MM-DD")
          endDate(formatString: "MMMM Do, YYYY")
          eventImage {
            fluid(maxWidth: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            fixed(width: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFixed_tracedSVG
            }
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          details {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
