import React from 'react';
import get from 'lodash/get'

import MentorCard from '../components/MentorCard';
import Layout from '../components/layout/Layout';

class Mentorship extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const mentors = get(this, 'props.data.allContentfulMentor.edges')

    return (
      <Layout>
        <section id="testimonials" className="py-20 lg:py-10">

          <div className="container mx-auto">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold mb-5 text-center">Mentorship</h1>
            <p className="text-xl mb-5 font-light text-center">Our mentorship program pulls in expertise from our global community to guide entrepreneurship in Africa.</p>
            <div className="text-center mb-10">
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
              <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
              <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
            </div>
            <div className="flex flex-col md:flex-row md:-mx-3">
              {mentors.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <MentorCard mentor={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Mentorship

export const pageQuery = graphql`
  query MentorshipQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulMentor(sort: { fields: [startDate], order: DESC }) {
      edges {
        node {
          name
          slug
          startDate(formatString: "MMMM Do, YYYY")
          profileImage {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
