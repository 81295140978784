import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-16 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Building and activating a global ecosystem of like-minded individuals bound by the aspiration to contribute to, share in, and learn from African tech ecosystems</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Relevant links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/s/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/s/imprint">Imprint</a>
          </li>
          <li>
            <a href="/s/cookie-policy">Cooking Policy</a>
          </li>
          <li>
            <a href="/careers">Careers</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Get in touch</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="mailto:team@abawt.io?subject=RE: Contact via website" target="_blank" title="Contact Us">Contact Us</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/abawt/" target="_blank" title="ABAWT LinkedIn">LinkedIn</a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCUzV-6yM1QBIFcBIzSffVjA" target="_blank" title="ABAWT YouTube">Past Events | YouTube</a>
          </li>
          <li>
            <a href="https://www.instagram.com/abawtio/" target="_blank" title="ABAWT Instagram">Instagram</a>
          </li>
          <li>
            <a href="https://medium.com/abawt" target="_blank" title="ABAWT Blog">Blog</a>
          </li>
          <li>
            <a href="https://www.twitter.com/abawtio/" target="_blank" title="ABAWT Twitter">Twitter</a>
          </li>
          <li>
            <a href="https://www.facebook.com/abawtio/" target="_blank" title="ABAWT Facebook">Facebook</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
