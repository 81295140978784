import React from 'react';
import get from 'lodash/get';
import { Helmet } from 'react-helmet'

import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';

class NotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <div>
        <Helmet title={`${siteTitle}`} />
        <section id="about" className="py-20 lg:pb-40 lg:pt-48">
          <div className="container mx-auto text-center">
            <div className="text-center max-w-xl mx-auto">
              <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-5">404. We seem a bit lost....</h2>
              <h3 className="text-xl mb-5 font-light">This page does not exist.</h3>
              <div className="text-center mb-10">
                <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-40 h-1 rounded-full bg-yellow-900"></span>
                <span className="inline-block w-3 h-1 rounded-full bg-yellow-900 ml-1"></span>
                <span className="inline-block w-1 h-1 rounded-full bg-yellow-900 ml-1"></span>
              </div>
              <h3 className="text-xl mb-5 font-light">Go back <a href="/">home</a></h3>
            </div>
          </div>
        </section>
        </div>
    )
  }
}


export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
