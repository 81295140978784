import React from 'react';
import get from 'lodash/get'

import Post from '../components/Post';
import Layout from '../components/layout/Layout';

class Blog extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout>
        <section id="testimonials" className="py-20 lg:py-10">
          <div className="container mx-auto">
            <h1 className="text-6xl md:text-7xl font-bold mb-5 text-center">Our Blog</h1>
            <div className="flex flex-col md:flex-row md:-mx-3">
              {posts.map(({ node }) => {
                return (
                  <div key={node.slug} className="flex-1 px-3">
                    <Post post={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query BlogPostQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
