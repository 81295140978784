import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import marked from 'marked'

import './static-page.module.css'
import Layout from '../components/layout/Layout'

class CareerTemplate extends React.Component {
  render() {
    const career = get(this.props, 'data.contentfulCareer')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <section id="static" className="py-20 lg:py-10">
          <div className="container mx-auto">
            <div style={{ background: '#fff' }}>
              <Helmet title={`${career.title} | ${siteTitle}`} />
              <h1 className="text-6xl md:text-7xl font-bold mb-5 mb-10">{career.title}</h1>
              {/* <h1 className="section-headline"></h1> */}
              <div className=""
                dangerouslySetInnerHTML={{
                  __html: marked(career.content.childMarkdownRemark.rawMarkdownBody),
                }}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default CareerTemplate

export const pageQuery = graphql`
  query CareerBySlug($slug: String!) {
    contentfulCareer(slug: { eq: $slug }) {
      title
      slug
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`
